import React, { Fragment, useMemo } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { formatDate } from "~utils";
import * as styles from "./index.module.scss";

export type News = Pick<
  GatsbyNewsQueryFragment,
  "slug" | "title" | "date" | "thumbnail"
>;

type Props = {
  news: News[];
};

export const NewsListHome: React.FC<Props> = ({ news }) => {
  const data = useStaticQuery(graphql`
    query NewsListHomeComponent {
      noimage: file(relativePath: { eq: "news/noimage.png" }) {
        publicURL
      }
    }
  `);

  const list = useMemo(
    () =>
      news.map((value) => {
        const { slug, title, date } = value;
        if (!slug) return null;
        const formattedDate = formatDate(date, "YYYY.MM.DD");
        return (
          <Fragment key={slug}>
            <Link to={`/news/${slug}`}>
              <div className={styles.Item}>
                <div>
                  <p className={styles.Date}>{formattedDate}</p>
                  {/* カテゴリを追加する際にはここに書く */}
                </div>
                <p className={styles.Title}>{title}</p>
              </div>
            </Link>
          </Fragment>
        );
      }),
    [data.noimage.publicURL, news],
  );

  return <div className={styles.Wrapper}>{list}</div>;
};
