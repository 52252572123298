import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Section } from "~components/templates/section";
import { SectionLayout } from "~components/templates/section-layout";
import { DocumentStyle } from "~components/templates/document-style";

export const Media: FC = () => {
  const data: GatsbyHomeMediaComponentQuery = useStaticQuery(graphql`
    query HomeMediaComponent {
      blog: file(relativePath: { eq: "top/blog_banner.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      magazine: file(relativePath: { eq: "top/magazine_banner.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className={styles.Media}>
      <Section background="subtle-gray">
        <SectionLayout>
          <DocumentStyle>
            <h2 className={styles.Heading}>
              <span>会社や</span>
              <span>セキュリティ知識について</span>
              <span>もっと知る</span>
            </h2>
          </DocumentStyle>
          <div className={styles.Wrapper}>
            <a className={styles.Link} href="https://blog.flatt.tech">
              <OptionalGatsbyImage className={styles.Banner} data={data.blog} />
            </a>
            <Link className={styles.Link} to="/magazine">
              <OptionalGatsbyImage
                className={styles.Banner}
                data={data.magazine}
              />
            </Link>
          </div>
        </SectionLayout>
      </Section>
    </div>
  );
};
