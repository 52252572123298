import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import { SectionLayout } from "~components/templates/section-layout";
import { SectionHeader } from "~components/templates/section-header";
import * as styles from "./index.module.scss";
import { NewsListHome } from "~components/utils/news-list-home";

type Props = {
  news: GatsbyNewsQueryFragment[];
};

export const News: React.FC<Props> = (props) => {
  const { news } = props;
  const data = useStaticQuery(graphql`
    query HomeNewsComponent {
      news: file(relativePath: { eq: "news.svg" }) {
        extension
        publicURL
      }
    }
  `);
  const newsProps = news.map((value) => ({
    slug: value.slug!,
    date: value.date!,
    title: value.title!,
    thumbnail: value.thumbnail,
  }));

  return (
    <section className={styles.news}>
      <SectionLayout>
        <header className={styles.news__header}>
          <SectionHeader imageSrc={data.news.publicURL} text="ニュース" />
        </header>
        <div className={styles.news__contents}>
          <NewsListHome news={newsProps} />
        </div>
        <Link to="/news">
          <Button className="news-path-title" buttonSize="Large">
            もっと見る
          </Button>
        </Link>
      </SectionLayout>
    </section>
  );
};
