import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SectionLayout } from "~components/templates/section-layout";
import { SectionHeader } from "~components/templates/section-header";
import * as styles from "./index.module.scss";

export const CompanyInfo: FC = () => {
  const data = useStaticQuery(graphql`
    query HomeCompanyComponent {
      company: file(relativePath: { eq: "company.svg" }) {
        extension
        publicURL
      }
    }
  `);
  return (
    <SectionLayout>
      <SectionHeader imageSrc={data.company.publicURL} text="会社概要" />
      <table className={styles.company__table}>
        <tbody>
          <tr>
            <th>会社名</th>
            <td>株式会社Flatt Security</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>〒113-0033 東京都文京区本郷3-43-16 コア本郷ビル2A</td>
          </tr>
          <tr>
            <th>設立</th>
            <td>2017年5月23日</td>
          </tr>
          <tr>
            <th>代表者名</th>
            <td>代表取締役 井手康貴</td>
          </tr>
          <tr>
            <th>事業内容</th>
            <td>サイバーセキュリティ関連サービス</td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>4億3042万円（資本準備金含む）</td>
          </tr>
          <tr>
            <th>顧問弁護士事務所</th>
            <td>法律事務所ZeLo</td>
          </tr>
          <tr>
            <th>顧問税理士事務所</th>
            <td>RSM汐留パートナーズ税理士法人</td>
          </tr>
        </tbody>
      </table>
    </SectionLayout>
  );
};

export const Company: FC = () => {
  return (
    <section className={styles.company}>
      <CompanyInfo />
    </section>
  );
};
