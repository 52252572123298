import React, { FC } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Button } from "~components/utils/button";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Language } from "~context/LanguageContext";

type Props = {
  language: Language;
};

export const LinkToCVE: FC<Props> = (props) => {
  const data: GatsbyHomeLinkToCveComponentQuery = useStaticQuery(graphql`
    query HomeLinkToCVEComponent {
      cve_bg: file(relativePath: { eq: "top/cve_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const { language } = props;

  if (language === "en") {
    return (
      <div className={styles.CVEProject}>
        <div className={styles.CVEProject_Wrapper}>
          <div className={styles.CVEProject_BackgroundImg}>
            <OptionalGatsbyImage data={data.cve_bg} alt="cve" />
          </div>
          <div className={styles.CVEProject_Container}>
            <h2 className={styles.CVEProject_Title}>
              Vulnerability Research Project
            </h2>
            <p className={styles.CVEProject_Description}>
              We, Flatt Security, are striving harder to improve the
              cybersecurity for the local and global community. We strongly
              believe that identifying and eradicating cyber weaknesses on
              products will eventually make the internet a much safer medium to
              communicate and utilize.
            </p>
            <Link to="/cve">
              <Button
                className={styles.CVEProject_LinkButton}
                buttonTheme="Secondary"
                buttonSize="Large"
              >
                List disclosed CVEs
              </Button>
            </Link>
            <Link to="/research">
              <Button
                className={styles.CVEProject_LinkButton}
                buttonTheme="Secondary"
                buttonSize="Large"
              >
                See blog articles
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.CVEProject}>
      <div className={styles.CVEProject_Wrapper}>
        <div className={styles.CVEProject_BackgroundImg}>
          <OptionalGatsbyImage data={data.cve_bg} alt="cve_background" />
        </div>
        <div className={styles.CVEProject_Container}>
          <h2 className={styles.CVEProject_Title}>
            脆弱性リサーチ
            <br className={styles.CVEProject_VisibleOnMobile} />
            プロジェクト
          </h2>
          <p className={styles.CVEProject_Description}>
            私たちはインターネット上で利用可能な様々なソフトウェア、ハードウェアの脆弱性を調査する「脆弱性リサーチプロジェクト」に取り組むことで、誰しもが安全にインターネットを利用できるようになることを信じています。本プロジェクトの実績はCVE一覧ページにて英語で公開しています。
          </p>
          <Link to="/cve" className={styles.CVEProject_button}>
            <Button
              className={styles.CVEProject_LinkButton}
              buttonTheme="Secondary"
              buttonSize="Large"
            >
              CVE
            </Button>
          </Link>
          <Link to="/research" className={styles.CVEProject_button}>
            <Button
              className={styles.CVEProject_LinkButton}
              buttonTheme="Secondary"
              buttonSize="Large"
            >
              リサーチ記事
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
