import React from "react";
import { graphql } from "gatsby";

import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { Catch } from "~components/home/catch";
import { Products } from "~components/home/products";
import { News } from "~components/home/news";
import { Company } from "~components/home/company";
import { Member } from "~components/home/member";
import * as styles from "./index.module.scss";
import { LinkToCVE } from "~components/home/link-to-cve";
import { Media } from "~components/home/media";
import { Section } from "~components/templates/section";
import { DocumentStyle } from "~components/templates/document-style";
import { Achievements } from "~components/home/achievements";

type Props = {
  data: GatsbyIndexPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const news = data.allContentfulNews.nodes;

  return (
    <>
      <PageLayout headerProps={{ useTransition: true }} paddingPattern="none">
        <div className={styles.Wrapper}>
          <Catch language="ja" />
          <Products language="ja" />
          <Media />
          <News news={news} />
          <Section>
            <DocumentStyle>
              <h2 className={styles.AchievementsHeading}>
                サービス提供実績
                <span className={styles.Achievements_Brackets}>(一部)</span>
              </h2>
            </DocumentStyle>
            <Achievements language="ja" />
          </Section>
          <LinkToCVE language="ja" />
          <Member />
          <Company />
        </div>
      </PageLayout>
    </>
  );
};

export default withContext(Component);

export const query = graphql`
  fragment NewsQuery on ContentfulNews {
    id
    slug
    title
    date
    body {
      body
    }
    thumbnail {
      gatsbyImageData(width: 720)
    }
  }
  query IndexPage {
    allContentfulNews(sort: { fields: date, order: DESC }, limit: 6) {
      nodes {
        ...NewsQuery
      }
    }
  }
`;
