import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SectionHeader } from "../../templates/section-header";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const Member: FC = () => {
  const data: GatsbyHomeMemberComponentQuery = useStaticQuery(graphql`
    query HomeMemberComponent {
      members: file(relativePath: { eq: "members.svg" }) {
        extension
        publicURL
      }
      member_ide: file(relativePath: { eq: "top/member_ide.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      member_yoneuchi: file(relativePath: { eq: "top/member_yoneuchi.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      member_toyoda: file(relativePath: { eq: "top/member_toyoda.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      member_shiga: file(relativePath: { eq: "top/member_shiga.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      member_uenosan: file(relativePath: { eq: "top/member_uenosan.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <section className={styles.Member}>
      <SectionHeader
        imageSrc={data.members?.publicURL ?? undefined}
        text="経営メンバー"
      />
      <div className={styles.MemberList}>
        <div className={styles.MemberItem}>
          <div className={styles.MemberImage}>
            <OptionalGatsbyImage data={data.member_ide} alt="koki_ide" />
          </div>
          <article className={styles.MemberBiograph}>
            <div className={styles.MemberName}>
              <h2>井手 康貴</h2>
              <h5>代表取締役CEO</h5>
            </div>
            <p>
              東京大学経済学部卒。在学中にメルカリ等、複数のテック系スタートアップでのエンジニア経験を経て、2017年5月にFlatt(現・Flatt
              Security）を執行役員・豊田と共に創業。(一社)セキュリティ・キャンプ協議会理事、国立研究開発法人情報通信研究機構（NICT）ナショナルサイバートレーニングセンター
              SecHack365実行委員を務める。
            </p>
          </article>
        </div>
        <div className={styles.MemberItem}>
          <div className={styles.MemberImage}>
            <OptionalGatsbyImage data={data.member_yoneuchi} alt="koki_ide" />
          </div>
          <article className={styles.MemberBiograph}>
            <div className={styles.MemberName}>
              <h2>米内 貴志</h2>
              <h5>取締役 CTO</h5>
            </div>
            <p>
              2019 年にFlatt
              Securityに入社し、2021年6月よりCTOに就任。(一社)セキュリティ・キャンプ協議会の一員として、情報セキュリティ技術の教育活動や、CTFの開催、運営にも参画している。著書に『
              <a href="//www.lambdanote.com/products/wbs">
                Webブラウザセキュリティ ―
                Webアプリケーションの安全性を支える仕組みを整理する
              </a>
              』（2021年、ラムダノート社）等。
            </p>
          </article>
        </div>
        <div className={styles.MemberItem}>
          <div className={styles.MemberImage}>
            <OptionalGatsbyImage data={data.member_toyoda} alt="koki_ide" />
          </div>
          <article className={styles.MemberBiograph}>
            <div className={styles.MemberName}>
              <h2>豊田 恵二郎</h2>
              <h5>執行役員 CCO</h5>
            </div>
            <p>
              東京大学工学部航空宇宙工学科卒。大学在学中よりデザイナーとして活動し、複数のプロダクトのWebマーケティング、UI/UXデザイン、プロダクト開発に携わる。2017年5月、CEOの井手と共にFlatt(現・Flatt
              Security)創業。創業以来、執行役員としてビジネスとクリエイティブの統括を行う。
            </p>
          </article>
        </div>
        <div className={styles.MemberItem}>
          <div className={styles.MemberImage}>
            <OptionalGatsbyImage data={data.member_shiga} alt="koki_ide" />
          </div>
          <article className={styles.MemberBiograph}>
            <div className={styles.MemberName}>
              <h2>志賀 遼太</h2>
              <h5>執行役員 プロフェッショナルサービス事業CTO</h5>
            </div>
            <p>
              2016年、LINE株式会社に入社し、セキュリティ診断や設計レビューを担当。2020年にFlatt
              Securityに入社し、2022年11月、執行役員兼プロフェッショナルサービス事業CTOに就任。数々のCTFで実績を残し運営にも携わっているほか、0day
              huntingの分野においても実績を残している。
            </p>
          </article>
        </div>
        <div className={styles.MemberItem}>
          <div className={styles.MemberImage}>
            <OptionalGatsbyImage data={data.member_uenosan} alt="koki_ide" />
          </div>
          <article className={styles.MemberBiograph}>
            <div className={styles.MemberName}>
              <h2>上野 宣</h2>
              <h5>社外取締役</h5>
            </div>
            <p>
              株式会社トライコーダ 代表取締役。
              <br />
              奈良先端科学技術大学院大学で情報セキュリティを専攻、2006年に株式会社トライコーダを設立。
              <br />
              OWASP Japan 代表、一般社団法人セキュリティ・キャンプ協議会
              GM、日本ハッカー協会理事、東京2020オリンピック・パラリンピック競技大会向け実践的演習「サイバーコロッセオ」推進委員などを歴任、第11回アジア・パシフィック情報セキュリティ・リーダーシップ・アチーブメント(ISLA)を受賞。
              <br />
              主な著書に『Webセキュリティ担当者のための脆弱性診断スタートガイド
              -
              上野宣が教える情報漏えいを防ぐ技術』、『HTTPの教科書』など他多数。
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};
