import React, { useMemo } from "react";

import * as styles from "./index.module.scss";

type Props = {
  imageSrc?: string;
  text?: string;
};

export const SectionHeader: React.FC<Props> = (props) => {
  const { imageSrc, text } = props;

  const Contents = useMemo(() => {
    if (!imageSrc && text) {
      return <h1 className={styles.SectionHeader_Title_Large}>{text}</h1>;
    }
    return (
      <>
        <img src={imageSrc} alt={text} className={styles.SectionHeader_Image} />
        <h1 className={styles.SectionHeader_Title}>{text}</h1>
      </>
    );
  }, [imageSrc, text]);

  return <div className={styles.SectionHeader}>{Contents}</div>;
};
