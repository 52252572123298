import dayjs from "dayjs";

const defaultDateFormat = "YYYY.MM.DD";

export const formatDate = (
  date: string,
  format: string = defaultDateFormat,
) => {
  const formatted = dayjs(date).format(format);
  return formatted;
};

export const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec));

export const getToday = () => {
  return formatDate(dayjs().toString());
};
